import "./App.css";
import "./assets/css/common.css";
// import Popup from "./componenets/main/popup";
import Homepage from "./layouts/main";

function App() {
    return (
        <>
            {/* <Popup /> */}
            <Homepage />
        </>
    );
}

export default App;
