import React from "react";
import styled from "styled-components";
import "../../assets/css/contents/pagination.css";

const Pagination = ({ postsPerPage, totalPosts, currentPage, onPageChange }) => {
    const pageNumbers = Math.ceil(totalPosts / postsPerPage);

    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
    };

    return (
        <div>
            <nav>
                <PageUl className="pagination">
                    <PageLi className={`page-item ${currentPage === 1 ? "disabled" : ""}`} onClick={() => handlePageChange(currentPage - 1)}>
                        <PageSpan>
                            <img src="/img/icon-left-arrow.png" alt="" />
                        </PageSpan>
                    </PageLi>
                    {Array.from({ length: pageNumbers }, (_, index) => (
                        <PageLi key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`} onClick={() => handlePageChange(index + 1)}>
                            <PageSpan className="page-link"> {index + 1}</PageSpan>
                        </PageLi>
                    ))}
                    <PageLi className={`page-item ${currentPage === pageNumbers ? "disabled" : ""}`} onClick={() => handlePageChange(currentPage + 1)}>
                        <PageSpan>
                            <img src="/img/icon-right-arrow.png" alt="" />
                        </PageSpan>
                    </PageLi>
                </PageUl>
            </nav>
        </div>
    );
};

export default Pagination;

const PageUl = styled.ul`
    max-width: 450px;
    display: flex;
    text-align: center;
    color: #494949;
    /* border-top: 2px solid #618ce1;
    border-bottom: 2px solid #618ce1; */
    background-color: transparent;
    padding: 8px 0;
`;

const PageLi = styled.li`
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 50%;
    margin: 0 3px;
    /* border: 1px solid #dddddd; */
    &:hover {
        cursor: pointer;
        color: #fff;
        background: #618ce1 0% 0% no-repeat padding-box;
    }
    &:focus::after {
        color: white;
        background: #618ce1 0% 0% no-repeat padding-box;
    }
    &:first-of-type:hover,
    &:last-of-type:hover {
        background: none;
    }
`;

const PageSpan = styled.span`
    &:hover::after,
    &:focus::after {
        border-radius: 100%;
        color: #fff;
        background: #618ce1 0% 0% no-repeat padding-box;
    }
`;
