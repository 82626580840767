import React from "react";

import "../../assets/css/contents/pageTitle.css";

export default function PageHeaderTitle(props) {
    return (
        <>
            <div className="pageTitle_container">
                <div className="contents_inner_container_layout">
                    <div className="pageTitle_box">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="pageTitle-sub">{props.subTitle}</div>
                        </div>
                        <h2>{props.title}</h2>
                        <p>{props.etc}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
