import React, { useState, useEffect } from "react";
import "../../assets/css/contents/fixSidebtn.css";
import { Link } from "react-router-dom";

export default function FixSideBtn() {
    const [showBtn, setShowBtn] = useState(false);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 100) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        };

        console.log(window.scrollY);
        window.addEventListener("scroll", handleShowButton);
        return () => {
            window.removeEventListener("scroll", handleShowButton);
        };
    }, []);

    return (
        <>
            {showBtn && (
                <div className="fixBtn_container">
                    <ul>
                        <li>
                            <Link to="/guide">
                                <button type="button" className="fixBtn">
                                    <img src="/img/file-document.png" alt="가이드" />
                                    <div>가이드</div>
                                </button>
                            </Link>
                        </li>
                        <li>
                            <Link to="/keep">
                                <button type="button" className="fixBtn">
                                    <img src="/img/work-alt.png" alt="유지보수" />
                                    <div> 유지보수</div>
                                </button>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/ask">
                                <button type="button" className="fixBtn">
                                    <img src="/img/comment.png" alt="문의하기" />
                                    <div>메일문의</div>
                                </button>
                            </Link>
                        </li> */}
                        <li>
                            <button type="button" onClick={scrollToTop} className="fixBtn toTopBtn">
                                <img src="/img/arrow-up.png" alt="상단으로 이동" />
                                <div>TOP</div>
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}
