import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/companyinfo_back.png";
import "../../assets/css/contents/company.css";

export default function CompanyInfo() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="핵심가치" sub={hide} subTitle="broanex" etc="" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="vision_box">
                        <div className="vision-title">
                            <p>브로넥스는 사람, 사회, 기술을 생각하며, 인재와 기술을 바탕으로 최고의 제품과 서비스를 창출하여 인류사회의 공헌을 궁극적인 목표로 삼고 있습니다.</p>
                        </div>
                        <div className="vision-cont">
                            {/* <div className="vision-cover">
                                <img src="/img/company_vision.png" alt="비전" />
                            </div> */}

                            <div className="vision_item">
                                <div className="vision-cover">
                                    <img src="/img/vision_01.png" alt="미래모습 아이컨" />
                                </div>
                                <div className="vision_item_text">
                                    <h4>
                                        <span>[미래모습]</span> Tangible Image
                                    </h4>
                                    <p>우리는 국내 최고의 통합 지능형 솔루션 소프트웨어 회사 끊이없이 성장하는 젊은 비전을 가진 회사 인재가 최고로 대우받는 회사가 된다.</p>
                                </div>
                            </div>
                            <div className="vision_item">
                                <div className="vision-cover">
                                    <img src="/img/vision_02.png" alt="핵심가치 아이콘" />
                                </div>
                                <div className="vision_item_text">
                                    <h4>
                                        <span>[핵심가치]</span> Tangible Image
                                    </h4>
                                    <p>우리는 Autonomy and Accountability(자율과 책임), Simple Perfection(단순하고 완벽함), Customer Centric(사용자 중심) 로 일한다.</p>
                                </div>
                            </div>
                            <div className="vision_item">
                                <div className="vision-cover">
                                    <img src="/img/vision_03.png" alt="목적 아이콘" />
                                </div>
                                <div className="vision_item_text">
                                    <h4>
                                        <span>[목적]</span> Tangible Image
                                    </h4>
                                    <p>
                                        우리는 세상에 둘도 없는 차별화된 소프트웨어를 개발하여 세상을 바꾸고, 재무적으로 초일류 회사를 만들고, 임직원에게 최고의 회사를 제공하기
                                        위해 일한다.
                                    </p>
                                </div>
                            </div>
                            <div className="vision_item">
                                <div className="vision-cover">
                                    <img src="/img/vision_04.png" alt="사명" />
                                </div>
                                <div className="vision_item_text">
                                    <h4>
                                        <span>[사명]</span> Tangible Image
                                    </h4>
                                    <p>
                                        우리는 사회발전을 위한 디지털혁신을 바탕으로 지능형 솔루션 소프트웨어 전 분야에 진출하여 매출 70억(2025년), 100억(2027년), 200억(2029년)의
                                        브로넥스 제국(Empire)을 건설한다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="value-box">
                        <div className="value-title">
                            <h4>브로넥스의 인재상</h4>
                            <p>도전과 열정으로 내재된 핵심가치를 실천할 때 브로넥스의 정신은 더욱 견고해집니다.</p>
                        </div>

                        <div className="value-cont">
                            <img src="/img/company-info.png" alt="브로넥스 핵심가치" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
