import React from "react";

import "../../assets/css/contents/pageTitle.css";
import styled from "styled-components";

export default function PageHeaderBackground(props) {
    return (
        <>
            <PageHeader className="pageTitle_container pageTitle_background" background={props.background}>
                <div className="contents_inner_container_layout">
                    <div className="pageTitle_box">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TitleSub sub={props.sub} className="pageTitle-sub">
                                {props.subTitle}
                            </TitleSub>
                        </div>
                        <h2>{props.title}</h2>
                        <p className="sub-w">{props.etc}</p>
                    </div>
                </div>
            </PageHeader>
        </>
    );
}

const PageHeader = styled.div`
    position: relative;
    background-image: url(${(props) => props.background});
    z-index: 1;
    display: flex;
    align-items: center;

    &::after {
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
    }
`;

const TitleSub = styled.div`
    display: ${(props) => (props.sub ? "block" : "none")};
`;
