import React from "react";
import "../assets/css/layouts/footer.css";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function Footer() {
    return (
        <>
            <footer className="footer_container_background">
                <div className="contents_inner_container_layout">
                    <div className="footer-nav_cont">
                        <Link to="" className="footer-nav_item">
                            오픈라이센스
                        </Link>

                        <Link to="" className="footer-nav_item">
                            개인정보취급방침
                        </Link>

                        <Link to="/ask" className="footer-nav_item link_color">
                            문의하기
                        </Link>

                        <button className="footer-nav_item linkBtn" onClick={() => window.open("http://pf.kakao.com/_xkwgNxb", "_blank")}>
                            카카오채널 바로가기
                            <span style={{ marginLeft: 4 }}>
                                <FaExternalLinkAlt />
                            </span>
                        </button>
                    </div>

                    <div className="footer-information">
                        <div className="footer-info_cont left-info_cont">
                            <div className="footer-shortcut">
                                <button onClick={() => window.open("https://www.youtube.com/@user-ny2gg8ve3l/featured", "_blank")} className="footer_icon-item linkBtn">
                                    <img src="/img/footer_youtube.png" alt="브로넥스 유튜브 바로가기" />
                                </button>

                                <button onClick={() => window.open("http://pf.kakao.com/_xkwgNxb", "_blank")} className="footer_icon-item linkBtn">
                                    <img src="/img/footer_kakao.png" alt="브로넥스 카카오 채팅" />
                                </button>

                                <button onClick={() => window.open("#")} className="footer_icon-item linkBtn">
                                    <img src="/img/footer_instar.png" alt="브로넥스 인스타 바로가기" />
                                </button>
                            </div>
                            <div className="info-list">
                                <h3>주소</h3>
                                <address>
                                    <strong>본사</strong> 경기도 수원시 영통구 영통로 237 에이스하이엔드타워 영통 510호, 511호
                                </address>
                            </div>
                        </div>
                        <div className="footer-info_cont right-info_cont">
                            <h3>
                                <img src="/img/broanex_login_logo.png" alt="" style={{ width: 160 }} />
                                <span className="hidden">브로넥스 홈페이지</span>
                            </h3>

                            <ul className="info-list">
                                <li>
                                    <strong>대표</strong>
                                    <span>오영탁</span>
                                </li>
                                <li>
                                    <strong>사업자등록번호</strong>
                                    <span>785-87-00363</span>
                                </li>
                                <li>
                                    <strong>TEL</strong>
                                    <span style={{ marginRight: 12 }}>031-306-0426</span>
                                    <strong>FAX</strong>
                                    <span> 0504-384-8237</span>
                                </li>
                                <li>
                                    <p> COPYRIGHT 2023 © BROANEX. ALL RIGHTS RESERVED</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
