import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/qms_back.png";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

export default function Qms() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="QMS" sub={hide} subTitle="DF-Designing" etc="빅데이터 분석 기반 통합 품질관리 솔루션" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>품질경영 시스템</h3>
                        <p>
                            브로넥스는 전사적 품질경영을 실천하고 있으며, 지속적인 품질 혁신 활동과 품질 개선을 통해 고객의 삶의 질 향상과 서비스를 제공하고
                            노력하고 있습니다.
                        </p>
                    </div>
                    <div className="product_system_cont">
                        <div className="product_system_cover">
                            <img src="/img/qms_system_img01.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            품질과 수율 향상을 통해 제조 경쟁력을 강화하세요.
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>
                            기업내 모든 프로세스에서 발생하는 데이터에 기반하여 높은 품질수준을 관리하고 <br />
                            빅데이터 분석을 통한 수율 및 생산성 향상에 기여합니다.
                        </p>
                    </div>
                    <div className="product_effect_cont product_feature_cont">
                        <div className="product_feature_item">
                            <img src="/img/view-solition1.png" alt="" />
                        </div>
                        <div className="product_feature_item">
                            <ul>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>1. 품질관리, 품질보증, 품질개선 업무에 대한 품질 경영 솔루션 </h4>
                                        <p>
                                            일관성 있는 품질결과를 위해 품질표준/도달수준 달성, 목표관리 기능을 <br /> 관리할 수 있게 합니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>2. 품질검사 지시 및 실적, 제품판정 결과 관리</h4>
                                        <p>
                                            수입검사 결과를 기초로 적헙여부를 판단하고 결과를 관련 부분에 피드백 후 수집된 검사실적 정보를 체계적으로
                                            분석관리하며, 완제품 검사결과를 기초로 적합여부를 판단하고 결과를 관련 부문에 전달한 수 수집된 검사실적 정보를
                                            체계적으로 분석관리할 수 있도록 지원합니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="analtyics_effect_list">
                                        <h4>3. 불량발생률 감소 및 제품경쟁력 강화</h4>
                                        <p>
                                            기타, 클레임관리를 통하여 거래처 불만이나 반품에 적절히 대응하여 동일한 불만이 지속되지 않도록 조치하고 결과는 즉시
                                            반영하며, 거래처 요구 시에 필요한 검사성적서를 발행할 수 있도록 지원합니다.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            제조 데이터 분석을 통한 품질과 수율향상
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <p>제조 데이터 분석과 시각화를 통해 불량의 원인을 찾고 제품 품질을 높여 생산성 향상을 지원합니다.</p>
                    </div>
                    <div className="product_coverList_cont">
                        <ul>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-monitoring.png" alt="" />
                                </div>
                                <p>품질과 수율에 대한 실시간 모니터링</p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-pie-chart.png" alt="" />
                                </div>
                                <p>다양한 분석기법을 통한 심화분석</p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-warning.png" alt="" />
                                </div>
                                <p>이상수율에 대한 사전 탐지 및 알람</p>
                            </li>
                            <li>
                                <div className="coverList_cover">
                                    <img src="/img/icon-magnifying-glass.png" alt="" />
                                </div>
                                <p>품질이상원인 파악 및 개산방안 제시</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>QMS 시스템 주요 화면</h3>
                    </div>
                    <div className="analtyics_layout_cont">
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/qms_ex1.png" alt="" style={{ height: 314 }} />
                                {/* <p>수입검사 등록</p> */}
                            </div>
                            <div>
                                <img src="/img/qms_ex2.jpg" alt="" />
                                {/* <p>공정검사 등록</p> */}
                            </div>
                        </div>
                        <div className="analtyics_layout_item">
                            <div>
                                <img src="/img/qms_ex3.jpg" alt="" />
                                {/* <p>부적합관리</p> */}
                            </div>
                            <div>
                                <img src="/img/qms_ex4.jpg" alt="" />
                                {/* <p>통계</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}
