import React from "react";
import { Link } from "react-router-dom";

export default function NoticePostList(props) {
    return (
        <div className="notice-list">
            <table className="list-title-box">
                <colgroup>
                    <col width="20%" />
                    <col width="60%" />
                    <col width="20%" />
                </colgroup>

                <thead>
                    <tr>
                        <th>{props.idNumber}</th>
                        <th>{props.titleName}</th>
                        <th>{props.titleData}</th>
                    </tr>
                </thead>
            </table>
            <table className="list-item-box">
                <colgroup>
                    <col width="20%" />
                    <col width="60%" />
                    <col width="20%" />
                </colgroup>
                <tbody>
                    {props.data.map((Element) => (
                        <tr className="notice-contents" key={Element.board_id}>
                            <td>{Element.board_id}</td>
                            <td>
                                <Link to={`/notice/${Element.board_id}`} className="noticeLink">
                                    <p>{Element.title}</p>
                                </Link>
                            </td>
                            {/* <td>
                            <p dangerouslySetInnerHTML={{ __html: Element.content }}></p>
                        </td> */}
                            <td>{Element.date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
