import React from "react";
// import { Link } from "react-router-dom";

export default function AskSection() {
    function winPopup() {
        var popUrl = "/#/askPop";
        var popName = "_blank";
        var popOption = "location=no, directories=no,resizable=no,status=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=800,left=50,top=30";
        window.open(popUrl, popName, popOption);
    }

    return (
        <>
            <section className="contents_container_layout background-ask">
                <div className="contents_inner_container_layout">
                    <h2 className="ask-title">
                        귀사의 시스템을 <strong>글로벌 수준의 데이터 기반 솔루션</strong>으로 업그레이드 하세요.
                    </h2>
                    <p className="ask-text">브로넥스는 고객이 필요로 하는 데이터 기반 시스템의 구축방안 수립부터 업무분석, 설계, 구축 이행의 모든 서비스를 제공합니다.</p>
                    <div style={{ textAlign: "center", marginTop: 60 }}>
                        <button type="button" className="ask-btn" onClick={winPopup}>
                            지금 문의하기
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}
