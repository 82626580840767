import React, { useState } from "react";

import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/suze_back.png";
import video from "../../assets/images/suez_act.mp4";
import "../../assets/css/contents/item.css";

export default function Suze() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="Suez" sub={hide} subTitle="broanex" etc="이송로봇 관제 시스템" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="suze_title_cont">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            노코드를 기반한 완벽한 통제 솔루션 Suez
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                        <video src={video} controls width={990}></video>
                        <p>
                            기존의 관리 및 설정이 어려웠던 이송 통제 솔루션에서 보다 완벽한 원격제어가 가능해진 브로넥스의 Suez 솔루션을 경험해보세요. <br />
                            Suez는 이해하기 쉬운 사용자 UI를 바탕으로 직관적인 통제 프로그램을 제공합니다.
                        </p>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="suez_layout_title">
                        <h3>
                            <span>
                                <img src="/img/double-quotes-left.png" alt="왼쪽 따옴표" />
                            </span>
                            Suez 이송 시스템을 쉽게 아용해보세요.
                            <span>
                                <img src="/img/double-quotes-right.png" alt="오른쪽 따옴표" />
                            </span>
                        </h3>
                    </div>
                    <div className="suez_layout_cont">
                        <div className="suez_layout_item">
                            <img src="/img/suez_auto1.jpg" alt="suze solution" />
                        </div>
                        <div className="suez_layout_item" style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
                            <h4 style={{ textAlign: "right" }}>누구나 자동제어를 시행할 수 있습니다.</h4>
                            <p style={{ textAlign: "right" }}>
                                직관적인 화면을 바탕으로 이해하기 쉬운 프로세스와 설정 방식으로 <br />
                                컴퓨터 언어 프로그래밍을 모르는 사용자여도 보다 쉽게 <br />
                                설정 및 유지보수가 가능합니다.
                            </p>
                        </div>
                    </div>
                    <div className="suez_layout_cont">
                        <div className="suez_layout_item">
                            <img src="/img/workers-in-control-room.jpg" alt="suze solution" />
                        </div>
                        <div className="suez_layout_item">
                            <h4>시뮬레이터를 기반으로 예측이 가능합니다.</h4>
                            <p>
                                시뮬레이터를 기반으로 시뮬레이션을 함으로써, 현장에서 해야될 90% 이상의
                                <br /> 작업을 사무실에서 완료 할 수 있습니다. 무엇보다, 높은 정확도로 물동량을 <br />
                                미리 예측할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-blue-w">
                <div className="contents_inner_container_layout">
                    <div className="suez_type_title">
                        <h3>Product Spotlight</h3>
                    </div>
                    <div className="suez_type_cont">
                        <ul>
                            <li>
                                <div className="suez_type_cover">
                                    <img src="/img/img2.png" alt="" />
                                </div>
                                <div className="suez_type_list">
                                    <h4>공장운영</h4>
                                    <p>
                                        대부분의 운영 방식이 공장 운영 방식입니다. 이는 보통 물리적인 공간이 협소한 곳에서 운영 및 운행 효율을 높이는 기술을
                                        많이 필요로 하며, 공간별 차량 대수 제한 및 버퍼 기능이 필수적으로 필요합니다. 복잡하고 어려운 기술이 많이 필요한 것에
                                        반해 효율은 그렇게 높진 않습니다.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="suez_type_cover">
                                    <img src="/img/img1.png" alt="" />
                                </div>
                                <div className="suez_type_list">
                                    <h4>창고운영</h4>
                                    <p>
                                        KIVA 전용에 가까운 운영 방식입니다. 최근에는 해외 업체(특히, 중국)들이 이 기술을 많이 사용하고 있습니다만, KIVA 형태가
                                        아니라면 운영 효율은 오히려 떨어지는 경우가 더 많습니다. 물리적인 공간만 확보할 수 있다면, 비교적 간단한 기술로 효율을
                                        크게 높일 수 있습니다.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="suez_type_cover">
                                    <img src="/img/img3.png" alt="" />
                                </div>
                                <div className="suez_type_list">
                                    <h4>AI 운영 최적화</h4>
                                    <p>
                                        14가지의 운영 및 운행 알고리즘을 통해 운영 효율을 극대화 하였습니다. 군집 최적화를 위해 AI 기술을 적용하였으며, AI 기반
                                        운영 최적화를 위해 AI를 연구하고 있습니다. 현재 대부분의 운영 알고리즘을 개발하였으며, 현재는 AI 연구&개발에 집중하고
                                        있습니다.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="suez_type_cover">
                                    <img src="/img/img4.png" alt="" />
                                </div>
                                <div className="suez_type_list">
                                    <h4>완벽한 충돌 방지</h4>
                                    <p>
                                        실시간 예측 충돌 알고리즘은 이송로봇 간의 충돌을 미리 예측하고 충돌하지 않은 지점까지 이송로봇이 멈추지 않고 계속
                                        운행되도록 하는 기술입니다.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}
