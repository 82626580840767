import React, { useEffect, useState } from "react";
import Axios from "axios";
// import PageHeaderTitle from "../../componenets/main/pageTitle";
import "../../assets/css/promotion/notice.css";
import Pagination from "../../componenets/post/pagination";
import NoticePostList from "../../componenets/post/noticePostList";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/notice-background.png";

export default function Notice() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      Axios.get(
        "https://t8oslha5s9.execute-api.ap-northeast-2.amazonaws.com/notice/getNoticeList",
      ).then((respones) => {
        setPosts(respones.data.reverse());
      });
    };
    fetchData();
  }, []);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const currentPosts = (posts) => {
    let currentPosts = 0;
    currentPosts = posts.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <PageHeaderBackground
        title="공지사항"
        sub={hide}
        background={background}
        subTitle="broanex"
        etc="브로넥스에서 전하는 새로운 소식을 확인해보세요."
      />
      {/* 게시물 */}
      <section className="contents_container_layout">
        <div className="contents_inner_container_layout">
          <NoticePostList
            idNumber="번호"
            titleName="제목"
            titleData="날짜"
            data={currentPosts(posts)}
          />
          <div className="page_item">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={posts.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </>
  );
}
