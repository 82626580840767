import React, { useState } from "react";
import Location from "../../componenets/company/Location";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/address_back.png";
import "../../assets/css/contents/map.css";

export default function Map(props) {
    const [hide, setHide] = useState(false);
    return (
        <>
            <PageHeaderBackground title="오시는길" sub={() => setHide(true)} subTitle="broanex" etc="" background={background} />
            {console.log(props)}
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="address_container">
                        <div className="address_cover">
                            <img src="/img/map-locator_icon.png" alt="주소 좌표" />
                        </div>
                        <div className="address_item">
                            <table>
                                <colgroup>
                                    <col width="20%" />
                                    <col width="80%" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>본사 주소</th>
                                        <td>
                                            <address>경기도 수원시 영통구 영통로 237 (신동 338) 에이스하이엔드타워 영통 5층 510호, 511호 (16679)</address>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>대표번호</th>
                                        <td>031-306-0426</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Location />
                </div>
            </section>
        </>
    );
}
