import React, { useState } from "react";

import "../../assets/css/contents/product.css";
import AskSection from "../../componenets/main/askSection";

import background from "../../assets/images/acs_back.png";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";

export default function AcsSimulation() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground sub={hide} title="ACS 시뮬레이션" subTitle="Suez" etc="Suez & ACS 시뮬레이션" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>운영 시뮬레이션</h3>
                        <p>실제 AGV 제어와 동일한 AGV 시뮬레이터와 실제 ACS로 운영 시뮬레이션을 합니다.</p>
                        <div className="page_sub_cover">
                            <img src="/img/Simulation_guidance_solution.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>ACS 시뮬레이션의 효과</h3>
                    </div>
                    <div className="contents-boxType">
                        <div className="contents-box-item suez-box-item">
                            <img src="/img/acsView01.png" alt="" />
                            <h3>Map & Command Report</h3>
                            <p>
                                MAP의 특징 및 명령의 구성을 한눈에 볼 수 있어, 시뮬레이션의 오류를 최소화합니다. 공사 소모품 및 예상 공사기간을 비교적 정확히
                                예측할 수 있습니다.
                            </p>
                        </div>
                        <div className="contents-box-item suez-box-item">
                            <img src="/img/acsView02.png" alt="" />
                            <h3>Vehicle Operating Report</h3>
                            <p>
                                AGV 가동률 및 충전율을 확인하여 실현성을 확인할 수 있으며 AGV 설계에 반영할 수 있습니다. Traffic 시간을 확인하여 최적 AGV 대수를
                                선정할 수 있습니다.
                            </p>
                        </div>
                        <div className="contents-box-item suez-box-item">
                            <img src="/img/acsView03.png" alt="" />
                            <h3>Command Report</h3>
                            <p>물동량 처리 결과를 한눈에 확인할 수 있습니다. 명령 처리 데이터를 토대로 차량 대수와 경로 및 운영 등을 최적화할 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>Suez ACS 시뮬레이션만의 특징</h3>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs05.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                누구나 쉽고 빠르게 <br />
                                정확한 지도 데이터를 생성합니다.
                            </h4>
                            <p>
                                경로를 다양하게 만들어 경로 최적화가 가능합니다.
                                <br />
                                자동으로 생성되는 관제/제어 데이터들은 실수가 없습니다.
                                <br />
                                소스코드 수정없이 거의 모든 세팅이 가능합니다.
                                <br />
                                비전문가도 쉽게 유지보수를 할 수 있어, 잦은 경로변경도 OK 입니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs06.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                운영 시뮬레이션을 통해
                                <br /> 잠재적인 문제를 100% 해결합니다.
                            </h4>
                            <p>
                                지도 데이터만 넣으면 운영 시뮬레이션 가능합니다.
                                <br />
                                자동으로 생성되는 리포트는 물동량 충족 및 실현성을 검증할 수 있습니다.
                                <br />
                                리포트를 통한 신뢰성 있는 시스템 제안은 수주확률을 높여줍니다.
                                <br />
                                시뮬레이션을 통한 최적화 작업은 운영효율을 40%까지 향상 시키며,
                                <br /> AGV 대수 최적화로 비용 절감까지 가능합니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs07.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                연결된 모든 장비의 정보를 <br />
                                신속, 정확하게 확인합니다.
                            </h4>
                            <p>
                                현장 시운전 전, 90% 이상의 시운전을 완료한다면 믿으시겠습니까?
                                <br />
                                잠재적인 문제 및 설계 에러를 미리 확인하고 해결 할 수 있습니다.
                                <br />
                                현장에 가지 않고도 정확한 테스트가 가능함으로
                                <br />
                                시운전 및 A/S 비용을 획기적으로 줄일 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div className="product_difference_cont">
                        <div className="product_difference_item">
                            <img src="/img/acs08.png" alt="MC solution" />
                        </div>
                        <div className="product_difference_item">
                            <h4>
                                다양한 형태의
                                <br /> AGV 주행제어 솔루션을 제공합니다.
                            </h4>
                            <p>
                                ACS와 GS를 함께 사용한다면, 시너지 효과가 극대화 됩니다.
                                <br />
                                ACS↔AGV 모두 검증되었음으로, 시운전이 필요 없습니다.
                                <br />
                                A/S 및 경로변경 비용이 무료 또는 최소화 됩니다.
                                <br />※ 많은 중복작업들이 사라짐으로 기본 ACS의 가격이 저렴합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-black-w">
                <div className="contents_inner_container_layout">
                    <div className="page_sub_title">
                        <h3>Suez ACS 시뮬레이션 만의 특장점</h3>
                        <p>자동으로 생성되는 리포트들은 신뢰성 있는 제안서 및 견적서를 제공합니다.</p>
                    </div>
                    <div className="product_coverList_cont product_effect_cont">
                        <ul>
                            <li>
                                <div className="product_effect_list">Fast Report 기술 탑재!</div>
                            </li>
                            <li>
                                <div className="product_effect_list">상위통신(MCS/MES) 연동!</div>
                            </li>
                            <li>
                                <div className="product_effect_list">100대 이상도 가능!</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*  문의 하기 */}
            <AskSection />
        </>
    );
}
