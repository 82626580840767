import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    // 페이지 이동 시 상단
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // 새로고침시 상단
    useEffect(() => {
        window.onbeforeunload = function pushRefresh() {
            window.scrollTo(0, 0);
        };
    }, []);

    return null;
}
