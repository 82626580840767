import React from "react";
import Header from "../componenets/header/header";
import Router from "../router/router";
import Footer from "../componenets/footer";
import { useLocation } from "react-router-dom";
import FixSideBtn from "../componenets/main/fixSideBtn";
import KaKaoAsk from "../componenets/main/kakaoAsk";

export default function Homepage() {
    const { pathname } = useLocation();

    return (
        <>
            {pathname === "/askPop" ? null : (
                <Header
                    headerChange={
                        pathname !== "/" &&
                        pathname !== "/address" &&
                        pathname !== "/about" &&
                        pathname !== "/history" &&
                        pathname !== "/case" &&
                        pathname !== "/guide" &&
                        pathname !== "/mes" &&
                        pathname !== "/DF-Designing" &&
                        pathname !== "/suez" &&
                        pathname !== "/analytics" &&
                        pathname !== "/qms" &&
                        pathname !== "/mc" &&
                        pathname !== "/mb" &&
                        pathname !== "/acs-simulation" &&
                        pathname !== "/acs-solution" &&
                        pathname !== "/news" &&
                        pathname !== "/notice" &&
                        pathname !== "/company"
                    }
                />
            )}
            <Router />
            {pathname === "/askPop" ? null : (
                <>
                    <FixSideBtn />
                    <KaKaoAsk />
                    <Footer />
                </>
            )}
        </>
    );
}
