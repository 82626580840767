import React from "react";
import "../../assets/css/layouts/banner.css";
import "../../assets/css/contents/home.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

export default function Home() {
    window.addEventListener("DOMContentLoaded", function () {
        let roller = document.querySelector(".company-banner-box");
        roller.id = "roller1";

        let clone = roller.cloneNode(true);
        clone.id = "roller2";
        document.querySelector(".company-rollingWrap").appendChild(clone);

        document.querySelector("#roller1").style.left = "0px";
        document.querySelector("#roller2").style.left = document.querySelector(".company-banner-box ul").offsetWidth + "px";

        roller.classList.add("original");
        clone.classList.add("clone");
    });
    return (
        <>
            {/* banner */}

            <Swiper
                effect={"fade"}
                autoplay={{
                    delay: 6500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Navigation, EffectFade, Pagination, Autoplay]}
                className="mySwiper"
                loop={true}
            >
                <SwiperSlide>
                    <section className="homepage_mainBN_container">
                        <div className="homepage_mainBN_inner_box">
                            <h2>
                                미래산업에 가치 있는 데이터를 더하다<br></br>
                                <span>"데이터 서비스 플랫폼 DFD"</span>
                            </h2>
                        </div>

                        <div className="homepage_mainBN_inner_box2">
                            <h3>DFD(Data Frame Designing) FACTORY</h3>
                            <p>데이터 서비스 플랫폼 “DFD’ 을 통하여 기업의 TCO 절감 및 혁신적 디지털 전환에 대한 해답을 드리고자 합니다.</p>
                        </div>
                    </section>
                </SwiperSlide>
                <SwiperSlide>
                    <section className="homepage_mainBN_container2">
                        <div className="homepage_mainBN_inner_box">
                            <h2>
                                미래산업에 가치 있는 데이터를 더하다<br></br>
                                <span>"데이터 서비스 플랫폼 DFD"</span>
                            </h2>
                        </div>

                        <div className="homepage_mainBN_inner_box2">
                            <h3>DFD(Data Frame Designing) FACTORY</h3>
                            <p>차별적인 ICT 기술과 새로운 프로세스 기반 서비스를 통해 기업의 경쟁력 강화와 성장을 지원합니다.</p>
                        </div>
                    </section>
                </SwiperSlide>
                <SwiperSlide>
                    <section className="homepage_mainBN_container3">
                        <div className="homepage_mainBN_inner_box">
                            <h2>
                                물류의 패러다임이 바뀝니다!
                                <br></br>
                                <span> “고객 현장에 최적화 된 Suez” </span>
                            </h2>
                        </div>

                        <div className="homepage_mainBN_inner_box2">
                            <h3>Suez는 다릅니다.</h3>
                            <p>모든 이송 로봇을 하나의 솔루션으로 누구나 쉽고 빠르게 개발하고 유지보수가 가능합니다.</p>
                        </div>
                    </section>
                </SwiperSlide>
                <SwiperSlide>
                    <section className="homepage_mainBN_container4">
                        <div className="homepage_mainBN_inner_box">
                            <h2>
                                물류의 패러다임이 바뀝니다!
                                <br></br>
                                <span> “고객 현장에 최적화 된 Suez” </span>
                            </h2>
                        </div>

                        <div className="homepage_mainBN_inner_box2">
                            <h3>Suez는 AI 기반 관제솔루션 입니다.</h3>
                            <p>Suez는 AI - Digital Twin 기반 ACS(AGV / AMR Control Solution)로 100% 순수 독자 기술로 만들어졌습니다.</p>
                        </div>
                    </section>
                </SwiperSlide>
            </Swiper>
            {/* 컨텐츠 */}

            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">스마트 팩토리 실현을 위해서는 무엇이 필요할까요?</h2>
                    <div className="home-flex-wrap">
                        <div className="home-flex-inner-box">
                            <div className="home-flex-cover">
                                <img src="/img/businessman-holding.png" alt="" />
                            </div>
                        </div>
                        <div className="home-flex-inner-box">
                            <ul>
                                <li>
                                    미래 지향적인 업체들은 보다 스마트한 비즈니스 방식으로 디지털 혁신을
                                    <br />
                                    주도해야 할 필요성을 가지고 있습니다.
                                </li>
                                <li>
                                    디지털 기술은 데이터 수집, 정보 가시성 및 현명한 의사결정을 위한 연결성과
                                    <br />
                                    상호 운용성을 통해 공장 운영을 혁신하여 수익성을 개선하고 자본 수익률을 극대화합니다.
                                </li>
                                <li>
                                    사람과 시스템 전반에 걸친 새로운 차원의 디지털 협업은 오늘날 역동적인 시장에서
                                    <br />
                                    경쟁력을 유지하기 위해 더 높은 운영 효율성, 품질 및 민첩성을 향상시킵니다.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <h2 className="home-flex-title">AI Powered Manufacturing Innovation</h2>
                    <div>
                        <div className="contents_understand_cover">
                            <img src="/img/smartFactoryunderstand.png" alt="" />
                        </div>
                        <div className="contents_understand_cover">
                            <img src="/img/smartFactoryunderstand_2.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="contents_container_layout background-homeContents">
                <div className="contents_inner_container_layout">
                    <div className="contents_title text_w">
                        {/* <h3>Pain Point</h3> */}
                        <h2>What We Do</h2>
                        <p>우리를 경험하면 다른 모든 곳에서의 경험을 불편하게 만든다.</p>
                    </div>
                    <div className="contents_table_wrap">
                        <div className="contents_table_itemBox">
                            <Link to="/mes">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType2.png" alt="" />
                                    </div>
                                    <h3>MES</h3>
                                    <p>생산 통합관리 시스템</p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/analytics">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType4.png" alt="" />
                                    </div>
                                    <h3>Analytics</h3>
                                    <p>
                                        기업의 수익률 제고를 위한
                                        <br />
                                        최적의 공급망 관리 솔루션
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/qms">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType9.png" alt="" />
                                    </div>
                                    <h3>QMS</h3>
                                    <p>
                                        제조업 경쟁력 강화를 위한 <br /> 제조분석 솔루션
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/mc">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType3.png" alt="" />
                                    </div>
                                    <h3>MC</h3>
                                    <p>
                                        팩토리구축을 위한 <br /> 통합생산관리&설비자동화
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/mb">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType8.png" alt="" />
                                    </div>
                                    <h3>MB</h3>
                                    <p>
                                        실시간 모니터링, <br />
                                        의사결정 솔루션
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/acs-solution">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType7.png" alt="" />
                                    </div>
                                    <h3>ACS 솔루션</h3>
                                    <p>
                                        효율적인 공장운영 및 <br /> 물류창고 자동화 <br />
                                        시스템구축을 위한 <br /> 물류로봇 솔루션
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="contents_table_itemBox">
                            <Link to="/acs-simulation">
                                <div>
                                    <div className="contents_table_item_cover">
                                        <img src="/img/workType6.png" alt="" />
                                    </div>
                                    <h3>ACS 시뮬레이션</h3>
                                    <p>통합모니터링 플랫폼</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="contents_container_layout backgrond-gray">
                <div className="contents_inner_container_layout">
                    <div className="contents_title">
                        <h3>Pain Point</h3>
                        <h2>고객의 상황에 맞는 최적의 생산관리 시스템을 제공합니다.</h2>
                    </div>
                    <div className="contents-boxType">
                        <div className="contents-box-item">
                            <img src="/img/icon1.png" alt="" />
                            <h3>사용자에게 직관적인 화면을 제공합니다.</h3>
                            <p>
                                플랫폼 구축에 있어 누구나 쉽게 이해할 수 있는 워크플로우 및 플랫폼 기본 탬플릿을 제공하며, 사전 구축된 탬플릿을 활용하여 보다
                                쉽게 플랫폼을 설계할 수 있습니다.
                            </p>
                        </div>
                        <div className="contents-box-item">
                            <img src="/img/icon2.png" alt="" />
                            <h3>높은 비즈니스 이해도를 반영합니다.</h3>
                            <p>
                                사용자가 직접 프로세스 구성에 참여하여 비즈니스 경험을 통해 누구보다 높은 이해도를 바탕으로 완성도 높은 플랫폼을 구축할 수
                                있습니다.
                            </p>
                        </div>
                        <div className="contents-box-item">
                            <img src="/img/icon3.png" alt="" />
                            <h3>개발 비용의 절감을 실현시킵니다.</h3>
                            <p>플랫폼 구축 프로세스를 간소화 하고 기존 개발보다 적은 비용으로 보다 빠른 서비스 이용이 가능합니다.</p>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="contents_title">
                        <h3>Service</h3>
                        <h2>생산과정을 체계적으로 관리하는 Onkeeper를 만나보세요.</h2>
                    </div>
                    <div className="contents_intro">
                        <div className="contents_intro-inner">
                            <div>
                                <img src="/img/home-cont1.png" alt="" />
                            </div>
                            <div className="intro-text">
                                <h3>service Explanation</h3>
                                <h2>
                                    모든 비즈니스를 위한 <strong>클라우드</strong> 솔루션
                                </h2>
                                <p>
                                    브로넥스에서 제공하는 DF-Designing은 컨테이너 기반 DevOps 체계 및 <br /> 개발/운영 거버넌스 체계 확립하여 PaaS 플랫폼 사용자
                                    및 관리자를 위한 <br /> 플랫폼 User Portal 구축합니다.
                                </p>
                            </div>
                        </div>
                        <div className="contents_intro-inner">
                            <div>
                                <img src="/img/home-cont2.png" alt="" />
                            </div>
                            <div className="intro-text">
                                <h3>service Explanation</h3>
                                <h2>
                                    최적의 현장맞춤 <strong>솔루션</strong>을 제공합니다.
                                </h2>
                                <p>
                                    브로넥스는 다년간의 솔루션 경험으로 사용자에게 최적화된 견적을 통해 <br /> 최상의 솔루션을 제공합니다. 비즈니스에
                                    지속가능성을 통합하도록 지원하는
                                    <br /> 전사적 기능과 산업 특화 기능을 제공합니다.​​
                                </p>
                            </div>
                        </div>
                        <div className="contents_intro-inner">
                            <div>
                                <img src="/img/home-cont3.png" alt="" />
                            </div>
                            <div className="intro-text">
                                <h3>service Explanation</h3>
                                <h2>
                                    S/W, H/W <strong>올인원</strong> 구축
                                </h2>
                                <p>
                                    브로넥스는 스마트 팩토리 및 AI 데이터 가공, 자동화 제어 부문의 소프트웨어, 하드웨어의 <br /> 각 분야별 최고의 수준의 인재와
                                    기술역량을 보유하고 고객에게 최상의 부가가치를 <br />
                                    제공하고 있습니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <div className="background_text"></div> */}
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="contents_title">
                        <h3>System feature</h3>
                        <h2>스마트 팩토리 도입효과</h2>
                    </div>
                    <div className="product_coverList_cont product_effect_cont" style={{ paddingTop: "0px" }}>
                        {/* <div className="product_effect_chartCover">
                            <img src="/img/mesEffect_chart.png" alt="" />
                        </div> */}
                        <ul>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart4.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>25%</h3> */}
                                    <p>
                                        실시간 품질정보 수집 및 분석관리로
                                        <br />
                                        <strong>공정불량률 25% 개선</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart5.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>9%</h3> */}
                                    <p>
                                        신속하고 일관성 있는 생산실적관리로
                                        <br />
                                        <strong>생산량 9% 증가</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart6.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>20%</h3> */}
                                    <p>
                                        정확한 생산 데이터 수집 및 관리로
                                        <br />
                                        <strong>재공재고 20% 개선</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="product_effect_cover">
                                    <img src="/img/Ring Chart7.png" alt="" />
                                </div>
                                <div className="product_effect_list">
                                    {/* <h3>18%</h3> */}
                                    <p>
                                        설비 가동상태 실시간 모니터링 및 관리로
                                        <br />
                                        <strong>설비 강동률 18% 증가</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="contents_title">
                        <h3>Our Clients</h3>
                        <h2>다양한 기업에서 브로넥스와 함께 하고 있습니다.</h2>

                        {/* <p>고객-기관-파트너를 연계한 초연결 Smart Factory Business를 지향합니다.</p> */}
                    </div>

                    {/* <div style={{ textAlign: "center", marginTop: 55 }}>
                        <Link to="/case">
                            <button className="readMore-btn">자세히보기</button>
                        </Link>
                    </div> */}
                </div>

                <div className="company-rollingbanner">
                    <div className="company-rollingWrap">
                        <div className="company-banner-box">
                            <ul>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/Hanwha_Corp_Momentum.jpg" alt="" style={{ width: 250 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/Hanwha_Corp_qcells.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/ECOPRO_BM_logo.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/cuchen_login_logo.png" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/yerim-color-K-ver.png" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/nexcon_header-logo.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/acelife_logo.png" alt="" style={{ width: 112 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/dst_logo.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/rse_header_logo.png" alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/pilko_login_logo.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                                <li>
                                    <div className="company-banner-item">
                                        <img src="/img/companyLogo/fashion_logo.png" alt="" style={{ width: 200 }} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contents_container_layout background-ask">
                <div className="contents_inner_container_layout">
                    <h2 className="ask-title">
                        귀사의 시스템을 <strong>글로벌 수준의 데이터 기반 솔루션</strong>으로 업그레이드 하세요.
                    </h2>
                    <p className="ask-text">
                        브로넥스는 고객이 필요로 하는 데이터 기반 시스템의 구축방안 수립부터 업무분석, 설계, 구축 이행의 모든 서비스를 제공합니다.
                    </p>
                    <div style={{ textAlign: "center", marginTop: 60 }}>
                        <Link to="/ask">
                            <button className="ask-btn">문의하기</button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}
