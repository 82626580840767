import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import PageHeaderTitle from "../../componenets/main/pageTitle";

export default function NoticePost() {
  const navigate = useNavigate(); // 뒤로가기 v6라서 usehistory =>navigate
  const [data, setData] = useState({});
  const { board_id } = useParams();

  useEffect(() => {
    try {
      Axios.get(
        "https://t8oslha5s9.execute-api.ap-northeast-2.amazonaws.com/notice/getNoticePost",
        {
          params: {
            board_id: board_id,
          },
        },
      ).then((response) => {
        setData(response.data[0]);
      });
    } catch (e) {
      console.error(e.message);
    }
  }, [board_id]);

  return (
    <>
      <PageHeaderTitle
        title="공지사항"
        subTitle="broanex"
        etc="브로넥스에서 전하는 새로운 소식을 확인해보세요."
      />
      <section className="contents_container_layout">
        <div className="contents_inner_container_layout">
          <div className="post-news-content">
            {data ? (
              <>
                <div className="post-title">
                  <table className="post-title_item">
                    <colgroup>
                      <col width="10%" />
                      <col width="90%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제목</th>
                        <td>{data.title}</td>
                      </tr>
                      <tr>
                        <th>작성일</th>
                        <td>{data.date}</td>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div
                  className="post-contents"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
              </>
            ) : (
              "해당 게시글을 찾을 수 없습니다."
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            <button
              className="listBack-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              목록보기
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
