import React, { useState } from "react";
import PageHeaderBackground from "../../componenets/main/pageTitleBackground";
import background from "../../assets/images/companyHistory_back.png";

export default function CompanyHistory() {
    const [hide, setHide] = useState(false);

    return (
        <>
            <PageHeaderBackground title="회사연혁" sub={hide} subTitle="broanex" etc="" background={background} />
            <section className="contents_container_layout">
                <div className="contents_inner_container_layout">
                    <div className="history-title">
                        <h3>브로넥스의 역사</h3>
                    </div>
                    <div>
                        <section className="history-year">
                            <h2>2022</h2>
                            <div className="history-year-inside">
                                <div className="history-contents">
                                    <strong>2022 미래로 향한 노력</strong>
                                    <ul>
                                        <li>Data Frame 기반 Designing 시스템 개발</li>
                                        <li>물류관제 시스템(ACS) 수에즈 (Suez) 개발</li>
                                        <li>블록체인 기반 공정관리 시스템 특허출원/등록</li>
                                        <li>온키퍼 상표 등록</li>
                                        <li>
                                            대중소 상생형 스마트공장 우수구축 사례 선정 (
                                            <a href="https://www.kbiz.or.kr/" target="_blank">
                                                https://www.kbiz.or.kr/
                                            </a>
                                            ㈜에이스라이프, 봉제협회)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="history-year">
                            <h2>2021</h2>
                            <div className="history-year-inside">
                                <div className="history-contents">
                                    <strong>2021 인재 육성형 기업의로서의 성장</strong>
                                    <ul>
                                        <li>병역특례 기업 지정</li>
                                        <li>기술혁신형중소기업 (이노비즈) 인증</li>
                                        <li>경영혁신형 중소기업(메인비즈) 인증</li>
                                        <li>IP(지식선정) 나래 선정</li>
                                        <li>근무혁신 인센티브 선정 기업 </li>
                                        <li>노동시간단축 확인 기업</li>
                                        <li>특성화고 인력양성 사업 선정</li>
                                        <li>인재 육성형 중소기업 지정</li>
                                        <li>소진공 스마트슈퍼 스마트기술 공급 업체 선정</li>
                                        <li>과학기술정보통신 K-ICT 창업 멘토링 선정</li>
                                        <li>오피스 온키퍼 솔루션 개발</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="history-year">
                            <h2>2019 ~ 2020</h2>
                            <div className="history-year-inside">
                                <div className="history-contents">
                                    <strong>2020 특허출원 및 B to C 솔루션 제품 출시</strong>
                                    <ul>
                                        <li>대중소 상생형 스마트공장 우수구축 사례 선정 (https://www.kbiz.or.kr/ ㈜도아드림,한성식품㈜)</li>
                                        <li>벤처기업 인증 연장 및 우수기술 평가 기업 선정</li>
                                        <li>중소기업 IP나래 선정 및 특허출원 (OPC-UA 프로토콜을 이용한 공장자동화 솔루션)</li>
                                        <li>통신판매업 신고 및 비브이패스 솔루션 출시</li>
                                        <li>출결 및 학습몰입도 관련 온키퍼 솔루션 출시</li>
                                    </ul>
                                </div>
                                <div className="history-contents">
                                    <strong>2019 산단 R&D 및 데이터바우처 사업 수행</strong>
                                    <ul>
                                        <li>스마트공장 추진단 우수구축 사례 선정 (https://www.smart-factory.kr/ 주식회사 퍼스프)</li>
                                        <li>대중소 상생형 현대차 스마트공장 공급기업 등록</li>
                                        <li>데이터바우처 가공기업 선정</li>
                                        <li>스마트제조혁신센터(안산)OPC-UA AML구축</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="history-year">
                            <h2>2016 ~ 2018</h2>
                            <div className="history-year-inside">
                                <div className="history-contents">
                                    <strong>2018 MAGIC ERP WEB 출시</strong>
                                    <ul>
                                        <li>대중소상생형 스마트공장 공급기업 등록</li>
                                        <li>스마트공장 추진단 우스공급기업 선정</li>
                                        <li>MAGIC POP 프로그램 개발</li>
                                        <li>한화정밀기계 협동로봇 기술파트너 선정</li>
                                        <li>기업부설연구소인정 (한국산업기술진흥협회)</li>
                                        <li>벤처기업인증(기술보증기금)</li>
                                    </ul>
                                </div>

                                <div className="history-contents">
                                    <strong>2017 브로넥스 자본금 증자 | 5,000만원</strong>
                                    <ul>
                                        <li>MAGIC CRM WEB 솔루션 개발</li>
                                        <li>MAGIC TAMS 솔루션 상용화</li>
                                        <li>연구개발전담부서인정 (한국산업기술진흥협회)</li>
                                    </ul>
                                </div>

                                <div className="history-contents">
                                    <strong>2016 브로넥스 설립 (2016.04.26)</strong>
                                    <ul>
                                        <li>MAGIC MES WEB | MAGIC 모니터링 | MAGIC ANDROID 등 주요 S/W 개발</li>
                                        <li>Q-ITAM 솔루션 상용화</li>
                                        <li>MAGIC QMS | SPC S/W 개발</li>
                                        <li>경북창조경제혁신센터 공급기업 등록</li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
}
